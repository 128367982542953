import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {userActions} from 'features/User';
import {WellniteContentDraftState} from 'interfaces';
import {Epic, ofType} from 'redux-observable';
import {concat, from, of, tap} from 'rxjs';
import {catchError, filter, ignoreElements, mergeMap} from 'rxjs/operators';
import {WellniteContentService} from 'services';

import {selectWellniteContentDraftStatusIsPending} from './wellniteContentSelectors';
import {wellniteContentActions} from './wellniteContentSlice';

const generateExpertSummaryEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteContentActions.generateExpertSummary.match),
    mergeMap(({payload: {onSuccess}}) =>
      from(WellniteContentService.generateExpertSummary()).pipe(
        mergeMap(res =>
          of(
            wellniteContentActions.generateExpertSummarySuccess(
              res.data.message.expertSummary,
            ),
          ).pipe(tap(() => onSuccess(res.data.message.expertSummary))),
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteContent',
                message,
              }),
            ),
            of(wellniteContentActions.generateExpertSummaryFailure(message)),
          ),
        ),
      ),
    ),
  );

const generateExpertSummaryFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteContentActions.generateExpertSummaryFailure.match),
    tap(({payload}) => {
      Toast({
        type: 'error',
        message: `${payload}`,
      });
    }),
    ignoreElements(),
  );

const generateWellniteContentEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteContentActions.generateWellniteContent.match),
    mergeMap(({payload: {onSuccess, ...payload}}) =>
      from(WellniteContentService.generateWellniteContent(payload)).pipe(
        mergeMap(res =>
          of(
            wellniteContentActions.generateWellniteContentSuccess(
              res.data.message.content,
            ),
          ).pipe(tap(() => onSuccess?.())),
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteContent',
                message,
              }),
            ),
            of(wellniteContentActions.generateWellniteContentFailure(message)),
          ),
        ),
      ),
    ),
  );

const generateWellniteContentFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteContentActions.generateWellniteContentFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const reGenerateWellniteContent: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteContentActions.reGenerateWellniteContent.match),
    mergeMap(({payload: {onSuccess, ...payload}}) => {
      const generateContent$ =
        payload.element === 'article'
          ? from(WellniteContentService.generateWellniteContent(payload))
          : from(WellniteContentService.reGenerateWellniteContent(payload));

      return generateContent$.pipe(
        mergeMap(res => {
          const {content} = res.data.message;
          const finalContent =
            payload.element === 'article'
              ? {
                  article: content.article,
                  references: content.references,
                }
              : content;

          return of(
            wellniteContentActions.reGenerateWellniteContentSuccess({
              ...finalContent,
              element: payload.element,
            }),
          ).pipe(tap(() => onSuccess?.(finalContent)));
        }),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteContent',
                message,
              }),
            ),
            of(
              wellniteContentActions.reGenerateWellniteContentFailure({
                error: message,
                element: payload.element,
              }),
            ),
          ),
        ),
      );
    }),
  );

const reGenerateWellniteContentFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteContentActions.generateWellniteContentFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const saveWellniteArticleEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteContentActions.saveWellniteArticle.match),
    mergeMap(({payload: {onSuccess, ...payload}}) =>
      from(WellniteContentService.saveWellniteArticle(payload)).pipe(
        mergeMap(res =>
          of(
            wellniteContentActions.saveWellniteArticleSuccess(
              res.data.message.article,
            ),
          ).pipe(tap(() => onSuccess?.(res.data.message.article))),
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteContent',
                message,
              }),
            ),
            of(wellniteContentActions.saveWellniteArticleFailure(message)),
          ),
        ),
      ),
    ),
  );

const saveWellniteArticleFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteContentActions.saveWellniteArticleFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const getWellniteImagesEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteContentActions.getWellniteImages.match),
    mergeMap(({payload}) =>
      from(WellniteContentService.getWellniteImages(payload)).pipe(
        mergeMap(res => [
          wellniteContentActions.getWellniteImagesSuccess(
            res.data.message.photos,
          ),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteContent',
                message,
              }),
            ),
            of(wellniteContentActions.getWellniteImagesFailure(message)),
          ),
        ),
      ),
    ),
  );

const getWellniteImagesFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteContentActions.getWellniteImagesFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const getWellniteArticleBySlugEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteContentActions.getWellniteArticleBySlug.match),
    mergeMap(({payload}) =>
      from(WellniteContentService.getWellniteArticleBySlug(payload)).pipe(
        mergeMap(res => [
          wellniteContentActions.getWellniteArticleBySlugSuccess(
            res.data.message.article,
          ),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteContent',
                message,
              }),
            ),
            of(wellniteContentActions.getWellniteArticleBySlugFailure(message)),
          ),
        ),
      ),
    ),
  );

const getWellniteArticleBySlugFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteContentActions.getWellniteArticleBySlugFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const getWellniteArticlesByProviderEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteContentActions.getWellniteArticlesByProvider.match),
    mergeMap(({payload}) =>
      from(WellniteContentService.getWellniteArticlesByProvider(payload)).pipe(
        mergeMap(res => [
          wellniteContentActions.getWellniteArticlesByProviderSuccess(
            res.data.message.articles,
          ),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteContent',
                message,
              }),
            ),
            of(
              wellniteContentActions.getWellniteArticlesByProviderFailure(
                message,
              ),
            ),
          ),
        ),
      ),
    ),
  );

const checkIfProviderReachedDailyWellniteContentLimitEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(
      wellniteContentActions.checkIfProviderReachedDailyWellniteContentLimit
        .match,
    ),
    mergeMap(() =>
      from(
        WellniteContentService.checkIfProviderReachedDailyWellniteContentLimit(),
      ).pipe(
        mergeMap(res => [
          wellniteContentActions.checkIfProviderReachedDailyWellniteContentLimitSuccess(
            res.data.message === 'You have reached the daily post limit',
          ),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteContent',
                message,
              }),
            ),
            of(
              wellniteContentActions.checkIfProviderReachedDailyWellniteContentLimitFailure(
                message,
              ),
            ),
          ),
        ),
      ),
    ),
  );

const triggerDownloadUnsplashPhotoEventEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteContentActions.triggerDownloadUnsplashPhotoEvent.match),
    mergeMap(({payload}) =>
      from(
        WellniteContentService.triggerDownloadUnsplashPhotoEvent(payload),
      ).pipe(
        mergeMap(() => [
          wellniteContentActions.triggerDownloadUnsplashPhotoEventSuccess(),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteContent',
                message,
              }),
            ),
            of(
              wellniteContentActions.triggerDownloadUnsplashPhotoEventFailure(
                message,
              ),
            ),
          ),
        ),
      ),
    ),
  );

const getWellniteContentDraftEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteContentActions.getWellniteContentDraft.match),
    mergeMap(() =>
      from(WellniteContentService.getWellniteContentDraft()).pipe(
        mergeMap(res =>
          of(
            wellniteContentActions.recoverWellniteContentDraft(
              res.data.message.draft,
            ),
          ),
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteContent',
                message,
              }),
            ),
            of(wellniteContentActions.getWellniteContentDraftFailure(message)),
          ),
        ),
      ),
    ),
  );

const autoSaveWellniteContentDraftEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    ofType(
      wellniteContentActions.generateWellniteContent.type,
      wellniteContentActions.generateWellniteContentSuccess.type,
      wellniteContentActions.reGenerateWellniteContentSuccess.type,
      wellniteContentActions.setWellniteArticle.type,
      wellniteContentActions.saveWellniteArticleSuccess.type,
      wellniteContentActions.resetWellniteContent.type,
    ),
    filter(() => !selectWellniteContentDraftStatusIsPending(state$.value)),
    mergeMap(action => {
      let data: Partial<WellniteContentDraftState> = {};

      switch (action.type) {
        case wellniteContentActions.generateWellniteContentSuccess.type:
        case wellniteContentActions.reGenerateWellniteContentSuccess.type:
        case wellniteContentActions.setWellniteArticle.type:
          data = {formStep: 'finalEdits'};
          break;
        case wellniteContentActions.generateWellniteContent.type:
          data = {
            formStep: 'topicSelection',
            content: null,
            article: null,
            topicSelection: null,
          };
          break;
        case wellniteContentActions.resetWellniteContent.type:
        case wellniteContentActions.saveWellniteArticleSuccess.type:
          data = {
            formStep: 'intro',
            content: null,
            article: null,
            topicSelection: null,
          };
          break;
        default:
      }

      const state = state$.value;
      const {content, article, topicSelection} = state.wellniteContent;
      const stateData = {content, article, topicSelection};

      const draftData: WellniteContentDraftState = {
        ...stateData,
        ...data,
        formStep: data.formStep ?? state.wellniteContent.formStep ?? 'intro',
      };

      return of(wellniteContentActions.saveWellniteContentDraft(draftData));
    }),
  );

const saveWellniteContentDraftEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteContentActions.saveWellniteContentDraft.match),
    mergeMap(({payload}) =>
      from(WellniteContentService.saveWellniteContentDraft(payload)).pipe(
        mergeMap(res =>
          of(
            wellniteContentActions.saveWellniteContentDraftSuccess(
              res.data.message.draft,
            ),
          ),
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteContent',
                message,
              }),
            ),
            of(wellniteContentActions.saveWellniteContentDraftFailure(message)),
          ),
        ),
      ),
    ),
  );

const saveWellniteContentDraftFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteContentActions.saveWellniteContentDraftFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

export const wellniteContentEpics = [
  generateExpertSummaryEpic,
  generateExpertSummaryFailureEpic,
  generateWellniteContentEpic,
  generateWellniteContentFailureEpic,
  reGenerateWellniteContent,
  reGenerateWellniteContentFailureEpic,
  saveWellniteArticleEpic,
  saveWellniteArticleFailureEpic,
  getWellniteImagesEpic,
  getWellniteImagesFailureEpic,
  getWellniteArticleBySlugEpic,
  getWellniteArticleBySlugFailureEpic,
  getWellniteArticlesByProviderEpic,
  checkIfProviderReachedDailyWellniteContentLimitEpic,
  triggerDownloadUnsplashPhotoEventEpic,
  getWellniteContentDraftEpic,
  autoSaveWellniteContentDraftEpic,
  saveWellniteContentDraftEpic,
  saveWellniteContentDraftFailureEpic,
].flatMap(epic => epic);
