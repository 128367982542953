import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {push} from 'connected-react-router';
import {userActions} from 'features/User';
import i18next from 'i18next';
import Cookies from 'js-cookie';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  ignoreElements,
  mergeMap,
  tap,
} from 'rxjs/operators';
import {ProviderService} from 'services/api/Provider';

import {airtableActions} from '../Airtable/airtableSlice';
import {providerActions} from '../providerSlice';

export const updateProviderProfileEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.updateProviderProfile.match),
    mergeMap(({payload}) =>
      from(ProviderService.updateProviderProfile(payload)).pipe(
        mergeMap(({data}) => [
          providerActions.updateProviderProfileSuccess(data.message),
          push('/dashboard'),
        ]),
        catchError((message: string) =>
          concat(
            of(userActions.setAsyncError({filter: 'provider', message})),
            of(providerActions.updateProviderProfileFailure()),
          ),
        ),
      ),
    ),
  );

export const updateProviderPreferredLanguageEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.updateProviderPreferredLanguage.match),
    mergeMap(({payload}) =>
      from(ProviderService.updateProviderPreferredLanguage(payload)).pipe(
        concatMap(({data}) => {
          Cookies.set('preferredLanguage', data.message.preferredLanguage);
          return from([
            providerActions.updateProviderPreferredLanguageSuccess(
              data.message,
            ),
            userActions.setUser(data.message),
            push('/dashboard'),
          ]);
        }),
        catchError((message: string) =>
          concat(
            of(userActions.setAsyncError({filter: 'provider', message})),
            of(providerActions.updateProviderPreferredLanguageFailure()),
          ),
        ),
      ),
    ),
  );

const updateProviderPreferredLanguageFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.updateProviderPreferredLanguageFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message});
      }
    }),
    ignoreElements(),
  );

const updateProviderPreferredLanguageSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.updateProviderPreferredLanguageSuccess.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({
          type: 'success',
          message: i18next.t(
            'preferredLanguageSetSuccess',
            'Preferred language updated successfully',
          ),
        });
      }
    }),
    ignoreElements(),
  );

const updateProviderProfileFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.updateProviderProfileFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message});
      }
    }),
    ignoreElements(),
  );

const updateProviderProfileSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.updateProviderProfileSuccess.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({
          type: 'success',
          message: i18next.t(
            'profileUpdatedSuccess',
            'Profile updated successfully',
          ),
        });
      }
    }),
    ignoreElements(),
  );

export const updateProviderProfileInfoEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.updateProviderProfileInfo.match),
    mergeMap(({payload: {onSuccess, role, ...payload}}) =>
      from(
        ProviderService.updateProviderProfileInfo({role, profileInfo: payload}),
      ).pipe(
        concatMap(({data}) =>
          from([
            providerActions.updateProviderProfileInfoSuccess(data.message),
            airtableActions.getAirtableProviderData(data.message.role),
            userActions.setUser(data.message),
          ]).pipe(
            tap(() => {
              if (onSuccess) {
                onSuccess();
              }
            }),
          ),
        ),
        catchError((message: string) =>
          concat(
            of(userActions.setAsyncError({filter: 'provider', message})),
            of(providerActions.updateProviderProfileInfoFailure()),
          ),
        ),
      ),
    ),
  );

const updateProviderProfileInfoFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.updateProviderProfileInfoFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message});
      }
    }),
    ignoreElements(),
  );

export const updateProviderProfilePictureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.updateProviderProfilePicture.match),
    mergeMap(({payload: {onSuccess, onError, ...rest}}) =>
      from(ProviderService.updateProviderProfilePicture(rest)).pipe(
        concatMap(({data: {message: user}}) => {
          onSuccess();
          return of(
            userActions.updateProfileImage(user.image),
            providerActions.updateProviderProfilePictureSuccess(),
            airtableActions.getAirtableProviderData(user.role),
          );
        }),
        catchError((message: string) => {
          onError();
          return concat(
            of(providerActions.updateProviderProfilePictureFailure(message)),
          );
        }),
      ),
    ),
  );

const updateProviderProfilePictureFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.updateProviderProfilePictureFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const updateProviderProfilePictureSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.updateProviderProfilePictureSuccess.match),
    tap(() => {
      Toast({
        type: 'success',
        message: 'Profile Picture updated successfully',
      });
    }),
    ignoreElements(),
  );
export const AccountEpics = [
  updateProviderProfileEpic,
  updateProviderProfileFailureEpic,
  updateProviderProfilePictureEpic,
  updateProviderProfilePictureFailureEpic,
  updateProviderProfilePictureSuccessEpic,
  updateProviderProfileSuccessEpic,
  updateProviderPreferredLanguageEpic,
  updateProviderPreferredLanguageFailureEpic,
  updateProviderProfileInfoEpic,
  updateProviderProfileInfoFailureEpic,
  updateProviderPreferredLanguageSuccessEpic,
];
