import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {push} from 'connected-react-router';
import {FormSubmissionStatus} from 'definitions/Onboarding';
import {userActions} from 'features/User';
import i18next from 'i18next';
import {ProviderFormType, ProviderFormWorkflow} from 'interfaces';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  ignoreElements,
  mergeMap,
  tap,
} from 'rxjs/operators';
import {AuthService} from 'services/api';
import {ProviderService} from 'services/api/Provider';
import {isUserFromUnitedStates} from 'utils';

import {providerActions} from '../providerSlice';

const signupEpic: Epic<AppActions, AppActions, RootState> = action$ =>
  action$.pipe(
    filter(providerActions.signup.match),
    mergeMap(({payload}) =>
      from(ProviderService.signup(payload)).pipe(
        mergeMap(({data: {message: user}}) => [
          providerActions.signupSuccess(),
          userActions.setUser(user),
          push({
            pathname: isUserFromUnitedStates(user)
              ? '/provider/registration/onboarding'
              : '/provider/international/registration',
          }),
        ]),
        catchError((message: string) =>
          concat(of(providerActions.signupFailure(message))),
        ),
      ),
    ),
  );

const signupFailureEpic: Epic<AppActions, AppActions, RootState> = action$ =>
  action$.pipe(
    filter(providerActions.signupFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const signupSuccessEpic: Epic<AppActions, AppActions, RootState> = action$ =>
  action$.pipe(
    filter(providerActions.signupSuccess.match),
    tap(() => {
      Toast({
        type: 'success',
        message: i18next.t('other.providerRegistrationSuccess'),
        position: 'bottom-left',
      });
    }),
    ignoreElements(),
  );

const getProviderDataEpic: Epic<AppActions, AppActions, RootState> = action$ =>
  action$.pipe(
    filter(providerActions.getProviderData.match),
    mergeMap(({payload}) =>
      from(AuthService.checkSession(payload)).pipe(
        mergeMap(({data: {message: user}}) => [
          providerActions.getProviderDataSuccess(user),
        ]),
        catchError(() => concat(of(providerActions.getProviderDataFailure()))),
      ),
    ),
  );

const unsubscribeOnboardingReminderEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.unsubscribeOnboardingReminder.match),
    mergeMap(({payload}) =>
      from(ProviderService.unsubscribeOnboardingReminder(payload)).pipe(
        mergeMap(() => [
          providerActions.unsubscribeOnboardingReminderSuccess(),
        ]),
        catchError(() =>
          concat(of(providerActions.unsubscribeOnboardingReminderFailure())),
        ),
      ),
    ),
  );

const unsubscribeOnboardingReminderSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.unsubscribeOnboardingReminderSuccess.match),
    tap(() => {
      Toast({
        type: 'success',
        message: `Unsubscribed Successfully!`,
        position: 'bottom-left',
      });
    }),
    ignoreElements(),
  );

const unsubscribeOnboardingReminderFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.unsubscribeOnboardingReminderFailure.match),
    tap(() => {
      Toast({
        type: 'error',
        message: `Error: could not unsubscribe`,
        position: 'bottom-left',
      });
    }),
    ignoreElements(),
  );

const changeProviderFromFirstTimeEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.changeProviderFromFirstTime.match),
    mergeMap(({payload}) =>
      from(ProviderService.changeProviderFromFirstTime(payload)).pipe(
        mergeMap(({message: user}) => [
          providerActions.changeProviderFromFirstTimeSuccess(user),
        ]),
        catchError(() =>
          concat(of(providerActions.changeProviderFromFirstTimeFailure())),
        ),
      ),
    ),
  );

const changeOnboardingVideoStatusEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.changeOnboardingVideoStatus.match),
    mergeMap(({payload}) =>
      from(ProviderService.changeOnboardingVideoStatus(payload)).pipe(
        mergeMap(({message: user}) => [
          providerActions.changeOnboardingVideoStatusSuccess(user),
        ]),
        catchError(() =>
          concat(of(providerActions.changeOnboardingVideoStatusFailure())),
        ),
      ),
    ),
  );

const completeOnboardingEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.completeOnboarding.match),
    mergeMap(({payload}) =>
      from(ProviderService.completeOnboarding(payload)).pipe(
        mergeMap(({message: user}) => [
          providerActions.completeOnboardingSuccess(user),
          userActions.checkSession(user.role),
          push({
            pathname: '/provider/availability',
          }),
        ]),
        catchError(error => {
          if (error === 'You need to finish selfie verification!') {
            Toast({type: 'error', message: error, position: 'bottom-left'});
            return concat(
              of(providerActions.completeOnboardingFailure()),
              of(push('/provider/international/registration/proof-of-id')),
            );
          }

          return concat(of(providerActions.completeOnboardingFailure()));
        }),
      ),
    ),
  );

const updateProviderNpiEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.updateProviderNpi.match),
    mergeMap(({payload}) =>
      from(ProviderService.updateProviderNpi(payload)).pipe(
        mergeMap(({message: provider}) => [
          providerActions.updateProviderNpiSuccess(provider),
        ]),
        catchError((message: string) =>
          concat(of(providerActions.updateProviderNpiFailure(message))),
        ),
      ),
    ),
  );

const updateProviderNpiFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.updateProviderNpiFailure.match),
    tap(() => {
      Toast({type: 'error', message: 'Could not update Provider NPI'});
    }),
    ignoreElements(),
  );

const updateProviderNpiSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.updateProviderNpiSuccess.match),
    tap(() => {
      Toast({
        type: 'success',
        message: `NPI updated successfully`,
        position: 'bottom-left',
      });
    }),
    ignoreElements(),
  );

const updateProviderResumeEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.updateProviderResume.match),
    mergeMap(({payload}) =>
      from(ProviderService.updateProviderResume(payload)).pipe(
        mergeMap(() => [providerActions.updateProviderResumeSuccess()]),
        catchError((message: string) =>
          concat(of(providerActions.updateProviderResumeFailure(message))),
        ),
      ),
    ),
  );

const updateProviderResumeFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.updateProviderResumeFailure.match),
    tap(() => {
      Toast({
        type: 'error',
        message: 'Failed to upload provider resume',
        position: 'bottom-left',
      });
    }),
    ignoreElements(),
  );

const updateProviderOnboarding: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ => {
  return action$.pipe(
    filter(providerActions.updateProviderData.match),
    mergeMap(({payload}) => {
      return from(ProviderService.updateProviderData(payload)).pipe(
        concatMap(({data: {message: provider}}) => {
          if (
            payload.step === 4 &&
            payload.formType === ProviderFormType.verification &&
            provider.onboarding?.formStatus?.verification ===
              FormSubmissionStatus.submitted
          ) {
            return [
              providerActions.updateProviderDataSuccess(provider),
              push({
                pathname: '/provider/registration/onboarding',
              }),
            ];
          }
          if (
            payload.step === 2 &&
            payload.formType === ProviderFormType.profile &&
            provider.onboarding?.formStatus?.profile ===
              FormSubmissionStatus.submitted
          ) {
            return [
              providerActions.updateProviderDataSuccess(provider),
              push({
                pathname: '/provider/registration/onboarding',
              }),
            ];
          }
          if (
            payload.formType === ProviderFormType.caqh &&
            provider.onboarding?.formStatus?.caqh ===
              FormSubmissionStatus.submitted
          ) {
            if (payload.workflow === ProviderFormWorkflow.dashboard) {
              return [providerActions.updateProviderDataSuccess(provider)];
            }
            return [
              providerActions.updateProviderDataSuccess(provider),
              push({
                pathname: '/provider/registration/onboarding',
              }),
            ];
          }
          if (
            payload.formType === ProviderFormType.onboarding &&
            provider.onboarding?.formStatus?.onboarding ===
              FormSubmissionStatus.submitted
          ) {
            return [
              providerActions.updateProviderDataSuccess(provider),
              userActions.checkSession(provider.role),
              push({
                pathname: '/provider/availability',
              }),
            ];
          }
          return [providerActions.updateProviderDataSuccess(provider)];
        }),
        catchError((message: string) =>
          concat(of(providerActions.updateProviderDataFailure(message))),
        ),
      );
    }),
  );
};
const updateProviderOnboardingSuccess: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ => {
  return action$.pipe(
    filter(providerActions.updateProviderDataSuccess.match),
    tap(() => {
      Toast({
        type: 'success',
        message: `Provider updated successfully`,
        position: 'bottom-left',
      });
    }),
    ignoreElements(),
  );
};

const updateProviderOnboardingFailure: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ => {
  return action$.pipe(
    filter(providerActions.updateProviderDataFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message});
      }
    }),
    ignoreElements(),
  );
};

const uploadLicenseCopyEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.uploadLicenseCopy.match),
    mergeMap(({payload}) =>
      from(ProviderService.uploadLicenseCopy(payload)).pipe(
        mergeMap(({data: {message}}) => [
          providerActions.uploadLicenseCopySuccess(message),
        ]),
        catchError((message: string) =>
          concat(of(providerActions.uploadLicenseCopyFailure(message))),
        ),
      ),
    ),
  );

const uploadLicenseCopyFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.uploadLicenseCopyFailure.match),
    tap(() => {
      Toast({
        type: 'error',
        message: `Failed to upload license certificate copy`,
        position: 'bottom-left',
      });
    }),
    ignoreElements(),
  );

const skipCAQHFormEpic: Epic<AppActions, AppActions, RootState> = action$ =>
  action$.pipe(
    filter(providerActions.skipCAQHForm.match),
    mergeMap(({payload}) =>
      from(ProviderService.skipCAQHForm(payload)).pipe(
        mergeMap(({message: provider}) => [
          providerActions.skipCAQHFormSuccess(provider),
        ]),
        catchError((message: string) =>
          concat(of(providerActions.skipCAQHFormFailure(message))),
        ),
      ),
    ),
  );

const verifyHumanEpic: Epic<AppActions, AppActions, RootState> = action$ =>
  action$.pipe(
    filter(providerActions.verifyHuman.match),
    mergeMap(({payload}) =>
      from(ProviderService.verifyHuman(payload)).pipe(
        mergeMap(({message}) => {
          if (message) {
            return [providerActions.verifyHumanSuccess()];
          } else {
            return [
              providerActions.verifyHumanFailure(
                i18next.t('other.verificationFailed'),
              ),
            ];
          }
        }),
        catchError((message: string) =>
          concat(of(providerActions.verifyHumanFailure(message))),
        ),
      ),
    ),
  );

const verifyHumanFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.verifyHumanFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const verifyHumanSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.verifyHumanSuccess.match),
    tap(() => {
      Toast({
        type: 'success',
        message: i18next.t('other.verificationSuccessful'),
        position: 'bottom-left',
      });
    }),
    ignoreElements(),
  );

const skipCAQHFormFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.skipCAQHFormFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const updateInternationalProviderCredentialsDataEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.updateInternationalProviderCrendentialsData.match),
    mergeMap(({payload}) =>
      from(
        ProviderService.updateInternationalProviderCredentialsData(
          payload.role,
          payload.formData,
        ),
      ).pipe(
        mergeMap(({data}) => [
          providerActions.updateInternationalProviderCrendentialsDataSuccess(
            data.message,
          ),
        ]),
        catchError((message: string) =>
          concat(
            of(
              providerActions.updateInternationalProviderCrendentialsDataFailure(
                message,
              ),
            ),
          ),
        ),
      ),
    ),
  );

const updateInternationalProviderCredentialsDataFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(
      providerActions.updateInternationalProviderCrendentialsDataFailure.match,
    ),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const updateInternationalProviderCredentialsDataSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(
      providerActions.updateInternationalProviderCrendentialsDataSuccess.match,
    ),
    tap(() => {
      Toast({
        type: 'success',
        message: i18next.t('other.providerCredentialUpdated'),
        position: 'bottom-left',
      });
    }),
    ignoreElements(),
  );

const updateInternationalProviderProfileDataEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.updateInternationalProviderProfileData.match),
    mergeMap(({payload}) =>
      from(
        ProviderService.updateInternationalProviderProfileData(
          payload.role,
          payload.formData,
        ),
      ).pipe(
        mergeMap(({data}) => {
          return [
            providerActions.updateInternationalProviderProfileDataSuccess(
              data.message,
            ),
            userActions.setUser(data.message),
          ];
        }),
        catchError((message: string) =>
          concat(
            of(
              providerActions.updateInternationalProviderProfileDataFailure(
                message,
              ),
            ),
          ),
        ),
      ),
    ),
  );

const updateInternationalProviderProfileDataFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.updateInternationalProviderProfileDataFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const updateInternationalProviderProfileDataSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.updateInternationalProviderProfileDataSuccess.match),
    tap(() => {
      Toast({
        type: 'success',
        message: i18next.t('other.providerProfileUpdated'),
        position: 'bottom-left',
      });
    }),
    ignoreElements(),
  );

const updateInternationalProviderProfileTwoDataEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.updateInternationalProviderProfileTwoData.match),
    mergeMap(({payload: {cb, ...rest}}) =>
      from(
        ProviderService.updateInternationalProviderProfileTwoData(
          rest.role,
          rest.formData,
        ),
      ).pipe(
        mergeMap(({data}) => {
          if (cb) cb();
          return [
            providerActions.updateInternationalProviderProfileTwoDataSuccess(
              data.message,
            ),
            userActions.setUser(data.message),
          ];
        }),
        catchError((message: string) =>
          concat(
            of(
              providerActions.updateInternationalProviderProfileTwoDataFailure(
                message,
              ),
            ),
          ),
        ),
      ),
    ),
  );

const updateInternationalProviderProfileTwoDataFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(
      providerActions.updateInternationalProviderProfileTwoDataFailure.match,
    ),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const updateInternationalProviderProfileTwoDataSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(
      providerActions.updateInternationalProviderProfileTwoDataSuccess.match,
    ),
    tap(() => {
      Toast({
        type: 'success',
        message: i18next.t('other.providerProfileUpdated'),
        position: 'bottom-left',
      });
    }),
    ignoreElements(),
  );

const uploadInternationalProviderIdDocumentEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.uploadInternationalProviderIdDocument.match),
    mergeMap(({payload}) =>
      from(
        ProviderService.uploadInternationalProviderIdDocument(
          payload.role,
          payload.formData,
        ),
      ).pipe(
        mergeMap(({data: {message}}) => [
          providerActions.uploadInternationalProviderIdDocumentSuccess(message),
        ]),
        catchError((message: string) =>
          concat(
            of(
              providerActions.uploadInternationalProviderIdDocumentFailure(
                message,
              ),
            ),
          ),
        ),
      ),
    ),
  );

const uploadInternationalProviderIdDocumentFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.uploadInternationalProviderIdDocumentFailure.match),
    tap(() => {
      Toast({
        type: 'error',
        message: i18next.t('other.idDocumentUploadFailure'),
        position: 'bottom-left',
      });
    }),
    ignoreElements(),
  );

const uploadInternationalProviderIdDocumentSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.uploadInternationalProviderIdDocumentSuccess.match),
    tap(() => {
      Toast({
        type: 'success',
        message: i18next.t('other.idDocumentUploaded'),
        position: 'bottom-left',
      });
    }),
    ignoreElements(),
  );

const sendInternationalProviderAgreedToTermsEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.sendInternationalProviderAgreedToTerms.match),
    mergeMap(({payload}) =>
      from(
        ProviderService.sendInternationalProviderAgreedToTerms(payload),
      ).pipe(
        mergeMap(({data: {message}}) => [
          providerActions.sendInternationalProviderAgreedToTermsSuccess(
            message,
          ),
        ]),
        catchError((message: string) =>
          concat(
            of(
              providerActions.sendInternationalProviderAgreedToTermsFailure(
                message,
              ),
            ),
          ),
        ),
      ),
    ),
  );

const sendInternationalProviderAgreedToTermsFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.sendInternationalProviderAgreedToTermsFailure.match),
    tap(() => {
      Toast({
        type: 'error',
        message: `Failed to agree to terms of service`,
        position: 'bottom-left',
      });
    }),
    ignoreElements(),
  );

export const RegisterEpics = [
  signupEpic,
  signupFailureEpic,
  signupSuccessEpic,
  getProviderDataEpic,
  changeProviderFromFirstTimeEpic,
  updateProviderNpiEpic,
  updateProviderNpiFailureEpic,
  updateProviderNpiSuccessEpic,
  updateProviderOnboarding,
  updateProviderOnboardingFailure,
  updateProviderOnboardingSuccess,
  uploadLicenseCopyEpic,
  uploadLicenseCopyFailureEpic,
  skipCAQHFormEpic,
  skipCAQHFormFailureEpic,
  changeOnboardingVideoStatusEpic,
  updateProviderResumeEpic,
  updateProviderResumeFailureEpic,
  completeOnboardingEpic,
  updateInternationalProviderCredentialsDataEpic,
  updateInternationalProviderCredentialsDataFailureEpic,
  updateInternationalProviderCredentialsDataSuccessEpic,
  updateInternationalProviderProfileDataEpic,
  updateInternationalProviderProfileDataFailureEpic,
  updateInternationalProviderProfileDataSuccessEpic,
  updateInternationalProviderProfileTwoDataEpic,
  updateInternationalProviderProfileTwoDataFailureEpic,
  updateInternationalProviderProfileTwoDataSuccessEpic,
  uploadInternationalProviderIdDocumentEpic,
  uploadInternationalProviderIdDocumentFailureEpic,
  uploadInternationalProviderIdDocumentSuccessEpic,
  sendInternationalProviderAgreedToTermsEpic,
  sendInternationalProviderAgreedToTermsFailureEpic,
  verifyHumanEpic,
  verifyHumanFailureEpic,
  verifyHumanSuccessEpic,
  unsubscribeOnboardingReminderEpic,
  unsubscribeOnboardingReminderSuccessEpic,
  unsubscribeOnboardingReminderFailureEpic,
];
