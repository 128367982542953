import {UserAccountType} from 'interfaces';

export type Label = {
  id: number;
  name: string;
  color: string;
};

export type AppointmentLabel = {
  id: number;
  patientFirstName: string;
  patientLastName: string;
  patientPhone: string;
  patientEmail: string;
  patientAccountType: UserAccountType;
  date: string;
  time: string;
  duration: string;
  appointmentTypeId: number;
  appointmentType: string;
  type: string;
  labels: Label[];
  canceled: boolean;
  // for group appointment
  notes?: string;
  classID?: number;
};

export type BillingLabels = {
  providerEmail: string;
  providerModelRemark: string[];
  appointmentData: AppointmentLabel[];
};

export enum ModelRemarks {
  Old_40_60 = 'Old ($40 - $60)',
  StJude = 'St. Jude',
  Scale = 'Scale',
  Hybrid = 'Hybrid',
  Combo = 'Combo',
  ComboAssociates = 'Combo Associates',
}

export enum AcuityLabelsName {
  canceled3hrs = 'Canceled 3 hrs',
  completed = 'Completed',
  dontChargeNoShow = 'Don’tChargeNoShow',
  freeSession = 'Free session',
  innetwork = 'In-network',
  mSmessup = 'MS mess up',
  noShow = 'No Show',
  paperworkAppt = 'Paperwork Appt',
  providerCancel = 'Provider Cancel',
  providerFree = 'Provider Free',
  providerMissed = 'Provider Missed',
  rescheduled = 'Rescheduled',
  techIssue = 'Tech Issue',
  techIssueFree = 'Free',
  dpoop = 'DP-OOP',
  dpoopCompleted = 'DP-OOP Completed',
  dpInNetworkCompleted = 'DP INN Completed',
  testAccount = 'Test Account',
  shortDuration = 'Short Duration',
  interested = 'Interested',
  notInterested = 'Not Interested',
  joined = 'Joined',
}

export enum AcuityLabelsId {
  canceled3hrs = 7015759,
  completed = 365545,
  dontChargeNoShow = 7867786,
  freeSession = 7644596,
  innetwork = 8289171,
  mSmessup = 7198909,
  noShow = 3929266,
  paperworkAppt = 8065762,
  providerCancel = 7327699,
  providerFree = 7336636,
  providerMissed = 4424698,
  rescheduled = 4623132,
  techIssue = 9119729,
  techIssueFree = 7198995,
  dpoop = 11378348,
  dpoopCompleted = 15553617,
  dpInNetworkCompleted = 14880302,
  testAccount = 9898034,
  shortDuration = 14733703,
}

export enum ProviderPaymentsTabs {
  overview = 'overview',
  history = 'history',
}

export enum ProviderPaymentsAppointmentTabs {
  normal = 'normal',
  group = 'group',
}
export enum ProviderRevenuePaymentsAppointmentTabs {
  singleSessions = 'singleSessions',
  groupSessions = 'groupSessions',
  digitalPracticeSessions = 'digitalPracticeSessions',
}

export enum AppointmentDurationTypes {
  prescriber10 = 'doctor appointment 10 minutes',
  prescriber15 = 'doctor appointment 15 minutes',
  prescriber30 = 'doctor appointment 30 minutes',
  prescriber45 = 'doctor appointment 45 minutes',
}

export type BillingHistoryTypes = {
  _id?: string;
  modelRemarks?: string[];
  // for current month TODO: keep consistent field name
  providerModelRemark?: string[];
  timesheetSent?: string;
  billables?: AppointmentLabel[];
  // for current month TODO: keep consistent field name
  appointmentData?: AppointmentLabel[];
  role?: string;
  billableHours?: {
    hour: number;
    minute: number;
  };
  totalHours?: {
    hour: number;
    minute: number;
  };
  deelOrderId?: string;
  providerId?: string;
  email?: string;
  createdAt?: string;
  updatedAt?: string;
  forMonth?: {
    description: string;
    range: {
      from: string;
      to: string;
    };
  };
  amount?: number;
  isInitialTherapyBillable?: boolean;
  totalHoursAvailableForConcludedCycle?: number;
};
