import {AppActions, RootState} from 'app/rootReducer';
import {push} from 'connected-react-router';
import {BaseProfile, IntercomEventTypes} from 'interfaces';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {catchError, filter, mergeMap, switchMap} from 'rxjs/operators';
import {AuthService} from 'services/api';
import {trackIntercomEvent} from 'services/api/Intercom/helpers';
import {
  isDigitalPracticeProviderYetToSetUpStripe,
  isMember,
  isProvider,
  isProviderFullyOnboarded,
  isUserFromUnitedStates,
} from 'utils';

import {userActions} from '../userSlice';

const hostname = window?.location?.hostname || '';

const otherRoleUrl = hostname.includes('stage')
  ? 'https://app.stage2.wellnite.co'
  : 'https://app.wellnite.co';

export const loginEpic: Epic<AppActions, AppActions, RootState> = action$ =>
  action$.pipe(
    filter(userActions.login.match),
    switchMap(({payload}) =>
      from(
        AuthService.login({
          email: payload.email.toLowerCase(),
          password: payload.password,
          keepSignedIn: true,
        }),
      ).pipe(
        mergeMap(({data: {message: user}}) => {
          if (isMember(user)) {
            trackIntercomEvent(IntercomEventTypes.PATIENT_LOGGED_IN, {
              userId: user?._id,
            });
          }

          if (isMember(user) || isProvider(user)) {
            let pathname = payload.redirectTo;
            if (isUserFromUnitedStates(user)) {
              pathname = !isProviderFullyOnboarded(user)
                ? '/provider/registration/onboarding'
                : isDigitalPracticeProviderYetToSetUpStripe(user)
                ? '/provider/profile/revenue'
                : pathname;
            }

            return [
              userActions.loginSuccess(),
              userActions.setUser(user),
              push({pathname}),
            ];
          } else {
            return [
              userActions.logoutOtherRole((user as BaseProfile).role),
              userActions.loginFailure(),
              userActions.setAsyncError({
                filter: 'user',
                message: `To login with your role, visit ${otherRoleUrl}`,
              }),
            ];
          }
        }),
        catchError((message: string) =>
          concat(
            of(userActions.loginFailure()),
            of(userActions.setAsyncError({filter: 'user', message})),
          ),
        ),
      ),
    ),
  );
export const loginUserEpics = [loginEpic];
